import React from 'react';
import JobList from './JobList';
import { useJobs } from 'graphql/hooks';

function JobBoard() {
  const { jobs, loading, error } = useJobs();

  console.log('[JobBoard] jobs:', { jobs, loading, error });
  if (loading) {
    return <p>Loading....</p>;
  }
  if (error) {
    return <p>Sorry, something went wrong</p>;
  }

  return (
    <div>
      <h1 className="title">N.A. Apollo Job Board</h1>
      <JobList jobs={jobs} />
    </div>
  );
}

export default JobBoard;
